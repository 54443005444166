import React,{useEffect} from 'react'
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from "../components/layout"
import ThemeContext from "../context/ThemeContext"


const IndexPage = ({ data }) => {

  console.log(data)

  useEffect(() => {

  });

var image = data.photos.edges[0];
  return (

    <ThemeContext.Consumer>
        {theme => (
    <div>
    <div id="page-wrapper">

      <h1>
        TRAINING
      </h1>
      <p>
        Training at Hof Marabunta

        {/*<Link to="/layoutless/">A layoutless page</Link> <br />*/}
      </p>
      <p>

      </p>
    </div>
    <div className="imageFullWidth">
    <GatsbyImage
     fluid={image.node.childImageSharp.fluid}
      alt={image.node.base.split("-").join(" ").split(".")[0]}
     image={getImage(image.node)}
   />
    </div>
    <div
    style={{
      margin: `0`,
      height: 600,
      padding: `30px`,
      background: `#d0cbc2`,
      color:`#111`
    }}
    >

    <h3>TRAINING AT <br/>HOF MARABUNTA</h3>
    <p>DEMO CONTENT --- Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
    </div>
    </div>
  )}
      </ThemeContext.Consumer>

  )
}

IndexPage.Layout = Layout

export default IndexPage

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }

    photos: allFile(
          sort: { fields: base, order: ASC }
          filter: {relativeDirectory: {eq: "training"}}
        ) {
          edges {
            node {
              id
              base
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  height: 1200
                  quality: 80
                  formats: AUTO
                  transformOptions: {fit: COVER, cropFocus: ATTENTION}

                )
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }

  }
`
